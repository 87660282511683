export const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const MINUTE_IN_MILLISECONDS = 1000 * 60;

export abstract class DateUtils {
  public static getDaysBetween(a: Date, b: Date): number {
    return Math.floor((b.getTime() - a.getTime()) / DAY_IN_MILLISECONDS);
  }

  public static getMinutesBetween(a: Date, b: Date): number {
    return Math.floor((b.getTime() - a.getTime()) / MINUTE_IN_MILLISECONDS);
  }

  public static addDays(date: Date, daysToAdd: number): Date {
    const result = new Date(date);

    result.setDate(result.getDate() + daysToAdd);

    return result;
  }
}
